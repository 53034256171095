<!--用户列表-->
<template>
  <div class="layout">
       <h1 class="title">没有权限</h1>
    <div class="header-wrap">
       <!-- <navBar></navBar> -->
    </div>
   
    <div class="tab-wrap">

      <!-- <Slider/> -->
      <!-- <AppMain/> -->
      <!--<transition name="fade">-->
           <!-- <router-view /> -->
      <!--</transition>-->
    </div>
  </div>
</template>
<script>
// import navBar from "./components/header"
// import Slider from "./components/Slider"
// import AppMain from "./components/AppMain"
  export default {
    name: 'layOut',
    components: {
        // navBar,
        // AppMain,
        // Slider
    },
    filters: {},
    data() {
      return {}
    },
    created() {
    },
    methods: {

    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
.title{
    height: 100px;
    width: 100px;
}
/*
.layout {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;

}
.header-wrap {
  display: flex;
  width: 100%;
  height: 60px;
}
.tab-wrap {
  display: flex;
  flex-direction: row;

  height: calc(100vh - 60px);


}
*/

</style>

